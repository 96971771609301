import {
    INCREMENT_PAGE_VISITED,
    RESET_PAGE_VISITED
  } from "../actions/FirstPageVisitedActions";
  
  const initialState = {
   firstPageVisited: 0
  };
  
  function firstPageVisited(state = initialState, action) {
    switch (action.type) {
      case INCREMENT_PAGE_VISITED:
        return Object.assign({}, state, {
          firstPageVisited: state.firstPageVisited + 1
        });
      case RESET_PAGE_VISITED:
        return Object.assign({}, state, {
          firstPageVisited: 0
        });
      default:
        return state;
    }
  }
  
  export default firstPageVisited;
  