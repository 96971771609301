import {
  GET_FAVORITES_REQUEST,
  GET_FAVORITES_SUCCESS,
  GET_FAVORITES_ERROR,
  ADD_FAVORITE_REQUEST,
  ADD_FAVORITE_SUCCESS,
  ADD_FAVORITE_ERROR,
  DELETE_FAVORITE_REQUEST,
  DELETE_FAVORITE_SUCCESS,
  DELETE_FAVORITE_ERROR,
  CLEAR_ALL_FAVORITES_DATA
} from "../actions/FavoritesActions";

const initialState = {
  getFavoritesLoading: false,
  addFavoriteLoading: false,
  deleteFavoriteLoading: false,
  favorites: [],
  restaurantId: "",

  //dine in
  getDineInFavoritesLoading: false,
  addDineInFavoriteLoading: false,
  deleteDineInFavoriteLoading: false,
  dineInFavorites: [],
  dineInRestaurantId: "",

  //pay
  getPayFavoritesLoading: false,
  addPayFavoriteLoading: false,
  deletePayFavoriteLoading: false,
  payFavorites: [],
  payRestaurantId: "",

  //QR
  getQRFavoritesLoading: false,
  addQRFavoriteLoading: false,
  deleteQRFavoriteLoading: false,
  QRFavorites: [],
  QRRestaurantId: "",
};

const getUpdatedFavoritesArray = (originalArray, type, favorite) => {
  let updatedArray = [];

  if (type === "add") {
    const favoriteObject = {
      product: {
        uuid: favorite?.productUuid,
      },
      size:
        favorite?.size
      ,
      instructions: favorite?.instructions,
      modifiers: favorite?.modifiers,
      qty: favorite?.qty,
      ingredientsExceptions: favorite?.ingredientsExceptions
    }

    updatedArray = [favoriteObject, ...originalArray];
    return updatedArray;
  }

  if (type === "delete") {
    originalArray?.forEach((row) => {
      if (row?.product?.uuid !== favorite?.product?.uuid) {
        updatedArray.push(row);
      }
    })
    return updatedArray;
  }
}

function favorites(state = initialState, action) {
  const appSource = action?.payload?.appSource;
  const restaurantIdState =
    appSource === "dine-in"
      ? "dineInRestaurantId"
      : appSource === "pay"
        ? "payRestaurantId"
        : appSource === "QR"
          ? "QRRestaurantId"
          : "restaurantId";
  const getFavoritesLoadingState =
    appSource === "dine-in"
      ? "getDineInFavoritesLoading"
      : appSource === "pay"
        ? "getPayFavortiesLoading"
        : appSource === "QR"
          ? "getQRFavoritesLoading"
          : "getFavoritesLoading";
  const addFavoriteLoadingState =
    appSource === "dine-in"
      ? "addDineInFavoriteLoading"
      : appSource === "pay"
        ? "addPayFavoriteLoading"
        : appSource === "QR"
          ? "addQRFavoriteLoading"
          : "addFavoriteLoading";
  const deleteFavoriteLoadingState =
    appSource === "dine-in"
      ? "deleteDineInFavoriteLoading"
      : appSource === "pay"
        ? "deletePayFavoriteLoading"
        : appSource === "QR"
          ? "deleteQRFavoriteLoading"
          : "deleteFavoriteLoading";
  const favoritesState =
    appSource === "dine-in"
      ? "dineInFavorites"
      : appSource === "pay"
        ? "payFavorties"
        : appSource === "QR"
          ? "QRFavorites"
          : "favorites";
  switch (action.type) {
    case GET_FAVORITES_REQUEST:
      return Object.assign({}, state, {
        [getFavoritesLoadingState]: action?.payload?.restaurantId === state[restaurantIdState] && state[favoritesState]?.length > 0 ? false : true,
      });
    case GET_FAVORITES_SUCCESS:
      return Object.assign({}, state, {
        [restaurantIdState]: action?.payload?.restaurantId,
        [getFavoritesLoadingState]: false,
        [favoritesState]: action?.payload?.favorites,
      });
    case GET_FAVORITES_ERROR:
      return Object.assign({}, state, {
        [getFavoritesLoadingState]: false,
        [favoritesState]: []
      });
    case ADD_FAVORITE_REQUEST:
      return Object.assign({}, state, {
        [addFavoriteLoadingState]: true,
        [restaurantIdState]: action?.payload?.restaurantId,
        [favoritesState]: getUpdatedFavoritesArray(state[favoritesState], "add", action?.payload?.data),
      });
    case ADD_FAVORITE_SUCCESS:
      return Object.assign({}, state, {
        [addFavoriteLoadingState]: false,
      });
    case ADD_FAVORITE_ERROR:
      return Object.assign({}, state, {
        [addFavoriteLoadingState]: false,
        [favoritesState]: getUpdatedFavoritesArray(state[favoritesState], "delete", action?.payload?.data),
      });
    case DELETE_FAVORITE_REQUEST:
      return Object.assign({}, state, {
        [deleteFavoriteLoadingState]: true,
        [restaurantIdState]: action?.payload?.restaurantId,
        [favoritesState]: getUpdatedFavoritesArray(state[favoritesState], "delete", action?.payload?.data),
      });
    case DELETE_FAVORITE_SUCCESS:
      return Object.assign({}, state, {
        [deleteFavoriteLoadingState]: false,
      });
    case DELETE_FAVORITE_ERROR:
      return Object.assign({}, state, {
        [deleteFavoriteLoadingState]: false,
        [favoritesState]: getUpdatedFavoritesArray(state[favoritesState], "add", action?.payload?.data),
      });
    case CLEAR_ALL_FAVORITES_DATA:
      return initialState;
    // return Object.assign({}, state, {
    //   [getFavoritesLoadingState]: false,
    //   [addFavoriteLoadingState]: false,
    //   [deleteFavoriteLoadingState]: false,
    //   [favoritesState]: [],
    //   [restaurantIdState]: "",
    //   [branchIdState]: ""
    // })
    default:
      return state;
  }
}

export default favorites;







// import {
//   ADD_TO_FAVORITES_SUCCESS,
//   REMOVE_FROM_FAVORITES_SUCCESS,
// } from '../actions/FavoritesActions';

// const initialState = {
//   favorites: [],
// };

// const handleAddToFavorites = (favorites, restoUuid, newProduct) => {
//   const favoriteIndex = favorites.findIndex(
//     (favorite) => favorite.restoUuid === restoUuid
//   );

//   if (favoriteIndex !== -1) {
//     // Resto UUID already exists in favorites array
//     const updatedFavorites = [...favorites];
//     updatedFavorites[favoriteIndex].favorites.push(newProduct);
//     return updatedFavorites;
//   } else {
//     // Resto UUID does not exist in favorites array
//     return [...favorites, { restoUuid, favorites: [newProduct] }];
//   }
// };

// const handleRemoveFromFavorites = (favorites, restoUuid, product) => {
//   const favoriteIndex = favorites.findIndex(
//     (favorite) => favorite.restoUuid === restoUuid
//   );

//   if (favoriteIndex !== -1) {
//     // Resto UUID exists in favorites array
//     const updatedFavorites = [...favorites];
//     updatedFavorites[favoriteIndex].favorites = updatedFavorites[
//       favoriteIndex
//     ].favorites.filter((favProduct) => favProduct !== product);
//     return updatedFavorites;
//   } else {
//     // Resto UUID does not exist in favorites array
//     return favorites;
//   }
// };

// function favorites(state = initialState, action) {
//   switch (action.type) {
//     case ADD_TO_FAVORITES_SUCCESS:
//       return Object.assign({}, state, {
//         favorites: handleAddToFavorites(state.favorites, action.payload.restoUuid, action.payload.newProduct),
//       });

//     case REMOVE_FROM_FAVORITES_SUCCESS:
//       const { restoUuid, product } = action.payload;
//       return Object.assign({}, state, {
//         favorites: handleRemoveFromFavorites(
//           state.favorites,
//           action.payload.restoUuid,
//           action.payload.product
//         ),
//       });

//     default:
//       return state;
//   }
// }

// export default favorites;
