
import { connect } from 'react-redux';
import CartBarSkelton from './CartBarSkeleton/CartBarSkeleton';
import './BottomBarSkeleton.css';

const BottomBarSkeleton = ({ hideCart, isQR, appSource, hasCart }) => {
  return (
    <div className='sk-bottom-bar'>
      {!hideCart && !(appSource === "QR" && !hasCart) && <CartBarSkelton/>}
      <div className='sk-bottom-links skeleton'>
        {' '}
        <div className='sk-circle-btn skeleton'></div>
        <div className='sk-media-circle'></div>
        <div className='sk-left-links'>
          <div className='sk-bar-link'>
            <div className='sk-icon skeleton light-gray'></div>
            <div className='sk-link skeleton light-gray'></div>
          </div>
          {!isQR && (<>
          <div className='sk-bar-link'>
            <div className='sk-icon skeleton light-gray'></div>
            <div className='sk-link skeleton light-gray'></div>
          </div></>)}
        </div>
        <div className='sk-right-links'>
          <div className='sk-bar-link'>
            <div className='sk-icon skeleton light-gray'></div>
            <div className='sk-link skeleton light-gray'></div>
          </div>
          {!isQR && (<>

          <div className='sk-bar-link'>
            <div className='sk-icon skeleton light-gray'></div>
            <div className='sk-link skeleton light-gray'></div>
            </div>
        </>     
          )}
           <div className='sk-bar-link sk-bar-link-cart'>
            <div className='sk-icon skeleton light-gray'></div>
            <div className='sk-link skeleton light-gray'></div>
            </div>
        </div>
        <div className='sk-bottom-bar-footer skeleton light-gray'></div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  appSource: state.appSource.appSource,
  hasCart: !state.organization.excludedModules?.includes("cart")
})

export default connect(mapStateToProps, null)(BottomBarSkeleton);
