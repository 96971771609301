import { connect } from "react-redux";
import GridItemSkeleton from "../ItemSkeleton/GridItemSkeleton";
import ListItemSkeleton from "../ItemSkeleton/ListItemSkeleton";
import "./ItemGroupSkeleton.css";

const ItemGroupSkeleton = ({ view, currentRestaurantModeDefaultView }) => {
  return (
    <div className="sk-item-group-wrapper">
      <div className="sk-item-group-title"></div>
      <div
        className={
          (view ? view : currentRestaurantModeDefaultView) === "grid"
            ? "sk-items-container grid"
            : "sk-items-container list"
        }
      >
        {[1, 2, 3, 4, 5, 6].map((itm, i) => {
          return (view ? view : currentRestaurantModeDefaultView) === "grid" ? (
            <GridItemSkeleton key={i} />
          ) : (
            <ListItemSkeleton key={i} />
          );
        })}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  view:
  state.appSource.appSource === "dine-in"
    ? state.products.dineInView
    : state.appSource.appSource === "pay"
    ? state.products.payView
    : state.appSource.appSource === "QR"
    ? state.products.QRView
    : state.products.view,
    currentRestaurantModeDefaultView: state.designs.currentRestaurantModeDefaultView,
});

export default connect(mapStateToProps, null)(ItemGroupSkeleton);
