import { connect } from "react-redux";
import GridEventSkeleton from "../GridEventSkeleton/GridEventSkeleton";
import ListEventSkeleton from "../ListEventSkeleton/ListEventSkeleton";
import "./EventsSkeleton.css";

const EventsSkeleton = ({
    view,
    currentRestaurantModeDefaultView
}) => {
    return (
          <div className={`news-sk-events-container ${(view ? view : currentRestaurantModeDefaultView) === "grid" ? "grid" : "list"}`}>
                    {(view ? view : currentRestaurantModeDefaultView) === "grid" ? 
                    <>
                    <GridEventSkeleton/>
                    <GridEventSkeleton/>
                    <GridEventSkeleton/>
                    <GridEventSkeleton/>
                    </>
                    : 
                    <>
                    <ListEventSkeleton/>
                    <ListEventSkeleton/>
                    <ListEventSkeleton/>
                    <ListEventSkeleton/>
                    </>          
                }
                </div>
    )
}

const mapStateToProps = (state) => ({
    view:
    state.appSource.appSource === "QR"
      ? state.events.QRView
      : state.appSource.appSource === "dine-in"
      ? state.events.dineInView
      : state.appSource.appSource === "pay"
      ? state.events.payView
      : state.events.view,
  currentRestaurantModeDefaultView: state.designs.currentRestaurantModeDefaultView,
});

export default connect(mapStateToProps, null)(EventsSkeleton);
