export const INCREMENT_PAGE_VISITED = "INCREMENT_FIRST_PAGE_VISITED";
export const RESET_PAGE_VISITED = "RESET_PAGE_VISITED";

export const actions = {
  incrementPageVisited: () => (dispatch) => {
    dispatch({
      type: INCREMENT_PAGE_VISITED
    })
  },
  resetPageVisited: () => (dispatch) => {
    dispatch({
      type: RESET_PAGE_VISITED
    })
  }
};
