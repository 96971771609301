import React, { Suspense, useState } from "react";
import { connect } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";
import HomeSkeleton from "../components/Skeletons/pages/HomeSkeleton/HomeSkeleton";
import RestaurantsSkeleton from "../components/Skeletons/pages/RestaurantsSkeleton/RestaurantsSkeleton";
import NewsSkeleton from "../components/Skeletons/pages/NewsSkeleton/NewsSkeleton";
import GallerySkeleton from "../components/Skeletons/pages/GallerySkeleton/GallerySkeleton";
import DeliveryAddressesSkeleton from "../components/Skeletons/pages/DeliveryAddressesSkeleton/DeliveryAddressesSkeleton";
import AddressLocationSkeleton from "../components/Skeletons/pages/AddressLocationSkeleton/AddressLocationSkeleton";
import AddressDetailsSkeleton from "../components/Skeletons/pages/AddressDetailsSkeleton/AddressDetailsSkeleton";
import OrdersSkeleton from "../components/Skeletons/pages/OrdersSkeleton/OrdersSkeleton";
import PreviousOrderSkeleton from "../components/Skeletons/pages/PreviousOrderSkeleton/PreviousOrderSkeleton";
import ReservationsSkeleton from "../components/Skeletons/pages/ReservationsSkeleton/ReservationsSkeleton";
import FavoritesSkeleton from "../components/Skeletons/pages/FavoritesSkeleton/FavoritesSkeleton";
import RestaurantRoute from "../routers/RestaurantRoute";
import DineInRoute from "../routers/DineInRoute";
import PayRoute from "../routers/PayRoute";
import QRRoute from "../routers/QRRoute";
import SplashScreen from "../pages/SplashScreen/SplashScreen";
import Loader from "../components/Loader/Loader";

const ProductDetails = React.lazy(() =>
    import("../pages/ProductDetails/ProductDetails")
);
const Restaurants = React.lazy(() => import("../pages/Restaurants/Restaurants"));
const Home = React.lazy(() => import("../pages/Home/Home"));
const Account = React.lazy(() => import("../pages/Account/Account"));
const EditAccount = React.lazy(() => import("../pages/EditAccount/EditAccount"));
const Cart = React.lazy(() => import("../pages/Cart/Cart"));
const Checkout = React.lazy(() => import("../pages/Checkout/Checkout"));
const DeliveryAddresses = React.lazy(() =>
    import("../pages/DeliveryAddresses/DeliveryAddresses")
);
const NewAddressLocation = React.lazy(() =>
    import("../pages/NewAddressLocation/NewAddressLocation")
);
const NewAddressInfo = React.lazy(() =>
    import("../pages/NewAddressInfo/NewAddressInfo")
);
const Reservation = React.lazy(() => import("../pages/Reservation/Reservation"));
const NewReservation = React.lazy(() =>
    import("../pages/NewReservation/NewReservation")
);
const MyReservations = React.lazy(() =>
    import("../pages/MyReservations/MyReservations")
);
const MyOrders = React.lazy(() => import("../pages/MyOrders/MyOrders"));
const UserAllergens = React.lazy(() =>
    import("../pages/UserAllergens/UserAllergens")
);
const Favorites = React.lazy(() => import("../pages/Favorites/Favorites"));
const PreviousOrder = React.lazy(() =>
    import("../pages/PreviousOrder/PreviousOrder")
);
const CurrentOrder = React.lazy(() => import("../pages/CurrentOrder/CurrentOrder"));
const OrderStatus = React.lazy(() => import("../pages/OrderStatus/OrderStatus"));
const Media = React.lazy(() => import("../pages/Media/Media"));
const Feedback = React.lazy(() => import("../pages/Feedback/Feedback"));
const News = React.lazy(() =>
    import("../components/Reservation/NewsSection/News/News")
);
const Gallery = React.lazy(() =>
    import("../components/Reservation/GallerySection/Gallery/Gallery")
);
const Info = React.lazy(() =>
    import("../components/Reservation/InfoSection/Info/Info")
);

const Login = React.lazy(() => import("../pages/Login/Login"));
const VerifyOTP = React.lazy(() => import("../pages/VerifyOTP/VerifyOTP"));
const Signup = React.lazy(() => import("../pages/Signup/Signup"));
const Messages = React.lazy(() => import("../pages/Messages/Messages"));
//temporal get otp page
const GetOTP = React.lazy(() => import("../pages/GetOTP/GetOTP"));
const PageNotFound = React.lazy(() =>
    import("../pages/PageNotFound/PageNotFound")
);

//import dine in page
const DineInHome = React.lazy(() =>
    import("../pages/DineInPages/DineInHome/DineInHome")
);
const PayBill = React.lazy(() => import("../pages/DineInPages/PayBill/PayBill"));
const PayBillPayment = React.lazy(() =>
    import("../pages/DineInPages/PayBillPayment/PayBillPayment")
);
const BillPaid = React.lazy(() =>
    import("../pages/DineInPages/BillPaid/BillPaid")
);

//import pay home page
const PayHome = React.lazy(() => import("../pages/PayPages/PayHome/PayHome"));

const RoutesContainer = (
    splashScreen,
    token,
    selectedRestaurant,
    getRestaurantsLoading,
    showSplash
) => {
    const [loginSkipped, setLoginSkipped] = useState(false);

    return (
        <Routes>
            {/* {showSplash && shouldShowSplash(lastAppOpenTime, 1) && splashScreen?.image !== "" && ( */}
            {splashScreen?.showSplash && splashScreen?.splashScreen?.image !== "" && (
                <Route
                    path="/"
                    element={<SplashScreen />}
                />
            )}
            <Route
                path="/"
                element={
                    <>
                        <div>
                            <Suspense fallback={<Loader />}>
                                {!token && !loginSkipped ? (
                                    <Navigate to={"/login"} replace />
                                ) : !selectedRestaurant ? (
                                    <Navigate to={"/select-restaurant"} replace />
                                ) : (
                                    <Navigate to={`/${selectedRestaurant}`} replace />
                                )}
                            </Suspense>
                        </div>
                    </>
                }
            />
            <Route

                path="/select-restaurant"
                element={
                    <div>
                        <Suspense fallback={<RestaurantsSkeleton />}>
                            <Restaurants />
                        </Suspense>
                    </div>
                }
            />

            {/* <Route
          path="/:restaurantId"
          element={
            <div>
              <Suspense fallback={<HomeSkeleton />}>
                <Home />
              </Suspense>
              <Outlet />
            </div>
          }
        > */}
            <Route
                path="/:restaurantId"
                element={
                    <RestaurantRoute
                        element={Home}
                        outlet={true}
                        isUserRoute={false}
                        elementFallback={getRestaurantsLoading ? <Loader /> : <HomeSkeleton />}
                    />
                }
            >
                <Route
                    path="product/:id"
                    element={
                        <div>
                            <Suspense fallback={<Loader darkBg={true} />}>
                                <ProductDetails />
                            </Suspense>
                        </div>
                    }
                />
                <Route
                    path="update/:id"
                    element={
                        <div>
                            <Suspense fallback={<Loader />}>
                                <ProductDetails />
                            </Suspense>
                        </div>
                    }
                />
            </Route>
            {/* <Route
          path=":restaurantId/news"
          element={
            <div>
              <Suspense fallback={<Loader/>}>
                <News />
              </Suspense>
            </div>
          }
        /> */}
            <Route
                path=":restaurantId/news"
                element={
                    <RestaurantRoute
                        element={News}
                        elementFallback={<NewsSkeleton isReservation={false} />}
                        isUserRoute={false}
                        from="news"
                    />
                }
            />
            {/* <Route
          path=":restaurantId/gallery"
          element={
            <div>
              <Suspense fallback={<Loader/>}>
                <Gallery />
              </Suspense>
            </div>
          }
        /> */}
            <Route
                path=":restaurantId/gallery"
                element={
                    <RestaurantRoute
                        element={Gallery}
                        elementFallback={<GallerySkeleton />}
                        isUserRoute={false}
                        from="gallery"
                    />
                }
            />
            {/* <Route
          path=":restaurantId/info"
          element={
            <div>
              <Suspense fallback={<Loader/>}>
                <Info />
              </Suspense>
            </div>
          }
        /> */}
            <Route
                path=":restaurantId/info"
                element={
                    <RestaurantRoute element={Info} isUserRoute={false} from="info" />
                }
            />
            <Route
                path="/signup"
                element={
                    <div>
                        <Suspense fallback={<Loader />}>
                            <Signup />
                        </Suspense>
                    </div>
                }
            />
            <Route
                path="/login"
                element={
                    <div>
                        <Suspense fallback={<Loader />}>
                            <Login setLoginSkipped={setLoginSkipped} />
                        </Suspense>
                    </div>
                }
            />
            <Route
                path="/login/verify"
                element={
                    <div>
                        <Suspense fallback={<Loader />}>
                            <VerifyOTP />
                        </Suspense>
                    </div>
                }
            />
            <Route
                path="/:restaurantId/reservation/*"
                element={
                    <div>
                        <Suspense fallback={<Loader />}>
                            <Reservation />
                        </Suspense>
                    </div>
                }
            />
            {/* <Route
            path="/:restaurantId/reservation/*"
            element={
              <RestaurantRoute
                element={MyReservations}
                elementFallback={ReservationsSkeleton}
                isUserRoute={false}
                from="reservation"
              />
            }
          /> */}

            {/* <Route
          path="/:restaurantId/reservation/newreservation"
          element={
            <div>
              <Suspense fallback={<Loader/>}>
                <NewReservation />
              </Suspense>
            </div>
          }
        /> */}

            <Route
                path="/:restaurantId/reservation/newreservation"
                element={
                    <RestaurantRoute
                        element={NewReservation}
                        isUserRoute={false}
                        from="reservation/newreservation"
                    />
                }
            />

            {/* <Route
          path="/:restaurantId/media"
          element={
            <>
              <Suspense fallback={<div style={{color:"white"}}>Loading...</div>}>
                <Media />
              </Suspense>
            </>
          }
        /> */}
            <Route
                path="/:restaurantId/media"
                element={
                    <RestaurantRoute
                        element={Media}
                        isUserRoute={false}
                        from="media"
                    />
                }
            />

            <Route
                path="/:restaurantId/media/:mediaId"
                element={
                    <RestaurantRoute
                        element={Media}
                        isUserRoute={false}
                        from="media"
                    />
                }
            />

            {/* USER ROUTES */}
            <Route
                path="/:restaurantId/messages"
                element={
                    <RestaurantRoute
                        element={Messages}
                        isUserRoute={true}
                        from="messages"
                    />
                }
            />
            <Route
                path="/:restaurantId/messages/:branchId"
                element={
                    <RestaurantRoute
                        element={Messages}
                        isUserRoute={true}
                        from="messages"
                    />
                }
            />
            <Route
                path="/:restaurantId/feedback"
                element={
                    <RestaurantRoute
                        element={Feedback}
                        isUserRoute={true}
                    />
                }
            />
            <Route path="/:restaurantId/account/">
                <Route
                    index
                    element={
                        <RestaurantRoute
                            element={Account}
                            isUserRoute={true}
                            from="account"
                        />
                    }
                />
                <Route
                    path="reservations"
                    element={
                        <RestaurantRoute
                            element={MyReservations}
                            elementFallback={ReservationsSkeleton}
                            isUserRoute={true}
                            from="account"
                        />
                    }
                />
                {/* <Route path="orders" element={<UserRoute element={MyOrders} />} /> */}
                <Route
                    path="orders"
                    element={
                        <RestaurantRoute
                            element={MyOrders}
                            elementFallback={OrdersSkeleton}
                            isUserRoute={true}
                            from="account"
                        />
                    }
                />
                {/* <Route
            path="feedback"
            element={
              <div>
                <Suspense fallback={<Loader/>}>
                  <Feedback />
                </Suspense>
              </div>
            }
          /> */}
                <Route
                    path="feedback"
                    element={
                        <RestaurantRoute
                            element={Feedback}
                            isUserRoute={true}
                            from="account"
                        />
                    }
                />
                {/* <Route path="favorites" element={<UserRoute element={Favorites} />} /> */}
                <Route
                    path="favorites"
                    element={
                        <RestaurantRoute
                            element={Favorites}
                            elementFallback={FavoritesSkeleton}
                            isUserRoute={true}
                            from="account"
                        />
                    }
                />
                <Route
                    path="edit-account"
                    element={
                        <RestaurantRoute
                            element={EditAccount}
                            isUserRoute={true}
                            from="account"
                        />
                    }
                />
            </Route>

            <Route
                path="/:restaurantId/allergens"
                element={
                    <RestaurantRoute
                        element={UserAllergens}
                        isUserRoute={true}
                        from="account"
                    />
                }
            />
            {/* <Route
          path="/:restaurantId/cart/"
          element={
            <div>
              <Suspense fallback={<Loader/>}>
                <Cart />
              </Suspense>
            </div>
          }
        /> */}
            <Route
                path="/:restaurantId/cart/"
                element={
                    <RestaurantRoute element={Cart} isUserRoute={false} from="cart" />
                }
            />
            {/* <Route
          path=":restaurantId/previous-order/:id"
          element={<UserRoute element={PreviousOrder} />}
        /> */}
            <Route
                path=":restaurantId/previous-order"
                element={
                    <RestaurantRoute
                        element={PreviousOrder}
                        elementFallback={PreviousOrderSkeleton}
                        isUserRoute={true}
                        from="account"
                    />
                }
            />
            <Route
                path=":restaurantId/current-order"
                element={
                    <RestaurantRoute
                        element={CurrentOrder}
                        //element fallback to be replaced with skeleton loader once ready
                        elementFallback={<Loader />}
                        isUserRoute={true}
                        from="account"
                    />
                }
            />
            <Route
                path=":restaurantId/order-status/:orderId/:branchId"
                element={
                    <RestaurantRoute
                        element={OrderStatus}
                        //element fallback to be replaced with skeleton once ready
                        elementFallback={<Loader />}
                        isUserRoute={true}
                    />
                }
            />
            {/* <Route
          path="/:restaurantId/checkout/"
          element={<UserRoute element={Checkout} />}
        /> */}
            <Route
                path=":restaurantId/checkout/"
                element={
                    <RestaurantRoute
                        element={Checkout}
                        isUserRoute={true}
                        from="cart"
                    />
                }
            />
            {/* <Route
          path="/:restaurantId/delivery-addresses/"
          element={<UserRoute element={DeliveryAddresses} />}
        /> */}
            <Route
                path="/:restaurantId/delivery-addresses/"
                element={
                    <RestaurantRoute
                        element={DeliveryAddresses}
                        elementFallback={DeliveryAddressesSkeleton}
                        isUserRoute={true}
                    />
                }
            />
            {/* <Route
          path="/:restaurantId/new-address-location/"
          element={<UserRoute element={NewAddressLocation} />}
        /> */}
            <Route
                path="/:restaurantId/new-address-location/"
                element={
                    <RestaurantRoute
                        element={NewAddressLocation}
                        elementFallback={AddressLocationSkeleton}
                        isUserRoute={true}
                    />
                }
            />
            <Route
                path="/:restaurantId/update-address-location/:addressId"
                element={
                    <RestaurantRoute
                        element={NewAddressLocation}
                        elementFallback={AddressLocationSkeleton}
                        isUserRoute={true}
                    />
                }
            />
            <Route
                path="/:restaurantId/select-location"
                element={
                    <RestaurantRoute
                        element={NewAddressLocation}
                        elementFallback={AddressLocationSkeleton}
                        isUserRoute={false}
                    />
                }
            />
            {/* <Route
          path="/:restaurantId/new-address-info/"
          element={<UserRoute element={NewAddressInfo} />}
        /> */}
            <Route
                path="/:restaurantId/new-address-info/"
                element={
                    <RestaurantRoute
                        element={NewAddressInfo}
                        elementFallback={AddressDetailsSkeleton}
                        isUserRoute={true}
                    />
                }
            />
            <Route
                path="/:restaurantId/update-address-info/:addressId"
                element={
                    <RestaurantRoute
                        element={NewAddressInfo}
                        elementFallback={AddressDetailsSkeleton}
                        isUserRoute={true}
                    />
                }
            />

            {/* DINE IN ROUTES */}
            <Route
                path="/dine-in/:restaurantId/:branchId/:table/home"
                element={
                    <DineInRoute
                        element={DineInHome}
                        // outlet={true}
                        isUserRoute={false}
                        elementFallback={<Loader />}
                    />
                }
            ></Route>

            <Route
                path="/dine-in/:restaurantId/:branchId/:table/menu"
                element={
                    <DineInRoute
                        element={Home}
                        isUserRoute={false}
                        outlet={true}
                        elementFallback={getRestaurantsLoading ? <Loader /> : <HomeSkeleton />}
                    />
                }
            >
                <Route
                    path="product/:id"
                    element={
                        <div>
                            <Suspense fallback={<Loader />}>
                                <ProductDetails />
                            </Suspense>
                        </div>
                    }
                />
                <Route
                    path="update/:id"
                    element={
                        <div>
                            <Suspense fallback={<Loader />}>
                                <ProductDetails />
                            </Suspense>
                        </div>
                    }
                />
            </Route>

            <Route
                path="dine-in/:restaurantId/:branchId/:table/news"
                element={
                    <DineInRoute
                        element={News}
                        elementFallback={<NewsSkeleton isReservation={false} />}
                        isUserRoute={false}
                        from="news"
                    />
                }
            />

            <Route
                path="dine-in/:restaurantId/:branchId/:table/gallery"
                element={
                    <DineInRoute
                        element={Gallery}
                        elementFallback={<GallerySkeleton />}
                        isUserRoute={false}
                        from="gallery"
                    />
                }
            />

            <Route
                path="dine-in/:restaurantId/:branchId/:table/info"
                element={
                    <DineInRoute element={Info} isUserRoute={false} from="info" />
                }
            />

            <Route
                path="/dine-in/:restaurantId/:branchId/:table/cart"
                element={<DineInRoute element={Cart} isUserRoute={false} />}
            ></Route>

            <Route
                path="/dine-in/:restaurantId/:branchId/:table/previous-order"
                element={
                    <DineInRoute
                        element={PreviousOrder}
                        elementFallback={PreviousOrderSkeleton}
                        isUserRoute={true}
                        from="account"
                    />
                }
            />
            <Route
                path="/dine-in/:restaurantId/:branchId/:table/current-order"
                element={
                    <DineInRoute
                        element={CurrentOrder}
                        //element fallback to be replaced with skeleton loader once ready
                        elementFallback={<Loader />}
                        isUserRoute={true}
                        from="account"
                    />
                }
            />
            <Route
                path="/dine-in/:restaurantId/:branchId/:table/pay-bill"
                element={<DineInRoute element={PayBill} isUserRoute={false} />}
            ></Route>
            <Route
                path="/dine-in/:restaurantId/:branchId/:table/pay-payment"
                element={
                    <DineInRoute element={PayBillPayment} isUserRoute={false} />
                }
            ></Route>
            <Route
                path="/dine-in/:restaurantId/:branchId/:table/bill-paid"
                element={<DineInRoute element={BillPaid} isUserRoute={false} />}
            ></Route>
            <Route
                path="/dine-in/:restaurantId/:branchId/:table/feedback"
                element={<DineInRoute element={Feedback} isUserRoute={true} />}
            ></Route>
            <Route
                path="/dine-in/:restaurantId/:branchId/:table/login"
                element={<DineInRoute element={Login} isUserRoute={false} />}
            ></Route>
            <Route path="/dine-in/:restaurantId/:branchId/:table/account/">
                <Route
                    index
                    element={
                        <DineInRoute
                            element={Account}
                            isUserRoute={true}
                            from="account"
                        />
                    }
                />
                <Route
                    path="reservations"
                    element={
                        <DineInRoute
                            element={MyReservations}
                            elementFallback={ReservationsSkeleton}
                            isUserRoute={true}
                            from="account"
                        />
                    }
                />
                <Route
                    path="orders"
                    element={
                        <DineInRoute
                            element={MyOrders}
                            elementFallback={OrdersSkeleton}
                            isUserRoute={true}
                            from="account"
                        />
                    }
                />

                <Route
                    path="feedback"
                    element={
                        <DineInRoute
                            element={Feedback}
                            isUserRoute={true}
                            from="account"
                        />
                    }
                />
                <Route
                    path="favorites"
                    element={
                        <DineInRoute
                            element={Favorites}
                            elementFallback={FavoritesSkeleton}
                            isUserRoute={true}
                            from="account"
                        />
                    }
                />

                <Route
                    path="edit-account"
                    element={
                        <DineInRoute
                            element={EditAccount}
                            isUserRoute={true}
                            from="account"
                        />
                    }
                />
            </Route>
            <Route
                path="/dine-in/:restaurantId/:branchId/:table/allergens"
                element={
                    <DineInRoute
                        element={UserAllergens}
                        isUserRoute={true}
                        from="account"
                    />
                }
            />

            <Route
                path="/dine-in/:restaurantId/:branchId/:table/media"
                element={
                    <DineInRoute element={Media} isUserRoute={false} from="media" />
                }
            />

            <Route
                path="/dine-in/:restaurantId/:branchId/:table/media/:mediaId"
                element={
                    <DineInRoute element={Media} isUserRoute={false} from="media" />
                }
            />

            {/* PAY ROUTES */}
            <Route
                path="/pay/:restaurantId/:branchId/:table/home"
                element={
                    <PayRoute
                        element={PayHome}
                        isUserRoute={false}
                        elementFallback={<Loader />}
                    />
                }
            ></Route>
            <Route
                path="/pay/:restaurantId/:branchId/:table/menu"
                element={
                    <PayRoute
                        element={Home}
                        isUserRoute={false}
                        outlet={true}
                        elementFallback={getRestaurantsLoading ? <Loader /> : <HomeSkeleton />}
                    />
                }
            >
                <Route
                    path="product/:id"
                    element={
                        <div>
                            <Suspense fallback={<Loader />}>
                                <ProductDetails />
                            </Suspense>
                        </div>
                    }
                />
                <Route
                    path="update/:id"
                    element={
                        <div>
                            <Suspense fallback={<Loader />}>
                                <ProductDetails />
                            </Suspense>
                        </div>
                    }
                />
            </Route>

            <Route
                path="pay/:restaurantId/:branchId/:table/news"
                element={
                    <PayRoute
                        element={News}
                        elementFallback={<NewsSkeleton isReservation={false} />}
                        isUserRoute={false}
                        from="news"
                    />
                }
            />

            <Route
                path="pay/:restaurantId/:branchId/:table/gallery"
                element={
                    <PayRoute
                        element={Gallery}
                        elementFallback={<GallerySkeleton />}
                        isUserRoute={false}
                        from="gallery"
                    />
                }
            />

            <Route
                path="pay/:restaurantId/:branchId/:table/info"
                element={
                    <PayRoute element={Info} isUserRoute={false} from="info" />
                }
            />

            <Route
                path="/pay/:restaurantId/:branchId/:table/cart"
                element={<PayRoute element={Cart} isUserRoute={false} />}
            ></Route>

            <Route
                path="/pay/:restaurantId/:branchId/:table/previous-order"
                element={
                    <PayRoute
                        element={PreviousOrder}
                        elementFallback={PreviousOrderSkeleton}
                        isUserRoute={true}
                        from="account"
                    />
                }
            />
            <Route
                path="/pay/:restaurantId/:branchId/:table/current-order"
                element={
                    <PayRoute
                        element={CurrentOrder}
                        //element fallback to be replaced with skeleton loader once ready
                        elementFallback={<Loader />}
                        isUserRoute={true}
                        from="account"
                    />
                }
            />
            <Route
                path="/pay/:restaurantId/:branchId/:table/pay-bill"
                element={<PayRoute element={PayBill} isUserRoute={false} />}
            >
            </Route>
            <Route
                path="/pay/:restaurantId/:branchId/:table/pay-payment"
                element={<PayRoute element={PayBillPayment} isUserRoute={false} />}
            ></Route>
            <Route
                path="/pay/:restaurantId/:branchId/:table/bill-paid"
                element={<PayRoute element={BillPaid} isUserRoute={false} />}
            ></Route>
            <Route
                path="/pay/:restaurantId/:branchId/:table/feedback"
                element={<PayRoute element={Feedback} isUserRoute={true} />}
            ></Route>
            <Route
                path="/pay/:restaurantId/:branchId/:table/login"
                element={<PayRoute element={Login} isUserRoute={false} />}
            ></Route>
            <Route path="/pay/:restaurantId/:branchId/:table/account/">
                <Route
                    index
                    element={
                        <PayRoute element={Account} isUserRoute={true} from="account" />
                    }
                />
                <Route
                    path="reservations"
                    element={
                        <PayRoute
                            element={MyReservations}
                            elementFallback={ReservationsSkeleton}
                            isUserRoute={true}
                            from="account"
                        />
                    }
                />
                <Route
                    path="orders"
                    element={
                        <PayRoute
                            element={MyOrders}
                            elementFallback={OrdersSkeleton}
                            isUserRoute={true}
                            from="account"
                        />
                    }
                />

                <Route
                    path="feedback"
                    element={
                        <PayRoute
                            element={Feedback}
                            isUserRoute={true}
                            from="account"
                        />
                    }
                />
                <Route
                    path="favorites"
                    element={
                        <PayRoute
                            element={Favorites}
                            elementFallback={FavoritesSkeleton}
                            isUserRoute={true}
                            from="account"
                        />
                    }
                />

                <Route
                    path="edit-account"
                    element={
                        <PayRoute
                            element={EditAccount}
                            isUserRoute={true}
                            from="account"
                        />
                    }
                />
            </Route>
            <Route
                path="/pay/:restaurantId/:branchId/:table/allergens"
                element={
                    <PayRoute
                        element={UserAllergens}
                        isUserRoute={true}
                        from="account"
                    />
                }
            />

            <Route
                path="/pay/:restaurantId/:branchId/:table/media"
                element={
                    <PayRoute element={Media} isUserRoute={false} from="media" />
                }
            />

            <Route
                path="/pay/:restaurantId/:branchId/:table/media/:mediaId"
                element={
                    <PayRoute element={Media} isUserRoute={false} from="media" />
                }
            />

            {/* QR ROUTES */}
            <Route
                path="/qr/:restaurantId/:branchId"
                exact
                element={
                    <QRRoute
                        element={Home}
                        isUserRoute={false}
                        outlet={true}
                        elementFallback={<HomeSkeleton />}
                    />
                }
            >
                {" "}
                <Route
                    path="product/:id"
                    element={
                        <div>
                            <Suspense fallback={<Loader />}>
                                <ProductDetails />
                            </Suspense>
                        </div>
                    }
                />
                <Route
                    path="update/:id"
                    element={
                        <div>
                            <Suspense fallback={<Loader />}>
                                <ProductDetails />
                            </Suspense>
                        </div>
                    }
                />
                {" "}
            </Route>
            <Route
                path="/error"
                element={
                    <Suspense fallback={<Loader />}>
                        <PageNotFound />
                    </Suspense>
                }
            ></Route>
            <Route
                path="/qr/:restaurantId/:branchId/news"
                element={
                    <QRRoute
                        element={News}
                        elementFallback={<NewsSkeleton isReservation={false} />}
                        isUserRoute={false}
                        from="news"
                    />
                }
            />
            <Route
                path="/qr/:restaurantId/:branchId/gallery"
                element={
                    <QRRoute
                        element={Gallery}
                        elementFallback={GallerySkeleton}
                        isUserRoute={false}
                        from="gallery"
                    />
                }
            />
            <Route
                path="/qr/:restaurantId/:branchId/info"
                element={<QRRoute element={Info} isUserRoute={false} from="info" />}
            />
            <Route
                path="/qr/:restaurantId/:branchId/feedback"
                element={<QRRoute element={Feedback} isUserRoute={true} />}
            />
            <Route
                path="/qr/:restaurantId/:branchId/cart"
                element={<QRRoute element={Cart} isUserRoute={false} />}
            />
            <Route
                path="/qr/:restaurantId/:branchId/media"
                element={<QRRoute element={Media} isUserRoute={false} />}
            />
            <Route
                path="/qr/:restaurantId/:branchId/media/:mediaId"
                element={
                    <QRRoute element={Media} isUserRoute={false} from="media" />
                }
            />

            <Route
                path="/qr/:restaurantId/:branchId/allergens"
                element={
                    <QRRoute
                        element={UserAllergens}
                        isUserRoute={true}
                        from="account"
                    />
                }
            />

               <Route path="/qr/:restaurantId/:branchId/account/">
                <Route
                    index
                    element={
                        <QRRoute
                            element={Account}
                            isUserRoute={true}
                            from="account"
                        />
                    }
                />
                <Route
                    path="reservations"
                    element={
                        <QRRoute
                            element={MyReservations}
                            elementFallback={ReservationsSkeleton}
                            isUserRoute={true}
                            from="account"
                        />
                    }
                />
                <Route
                    path="orders"
                    element={
                        <QRRoute
                            element={MyOrders}
                            elementFallback={OrdersSkeleton}
                            isUserRoute={true}
                            from="account"
                        />
                    }
                />

                <Route
                    path="feedback"
                    element={
                        <QRRoute
                            element={Feedback}
                            isUserRoute={true}
                            from="account"
                        />
                    }
                />
                <Route
                    path="favorites"
                    element={
                        <QRRoute
                            element={Favorites}
                            elementFallback={FavoritesSkeleton}
                            isUserRoute={true}
                            from="account"
                        />
                    }
                />

                <Route
                    path="edit-account"
                    element={
                        <QRRoute
                            element={EditAccount}
                            isUserRoute={true}
                            from="account"
                        />
                    }
                />
            </Route>
            {/* appSource !== "QR" ? ( */}
            <Route
                path="*"
                element={
                    <Suspense fallback={<Loader />}>
                        <Navigate to={"/error"} replace />
                    </Suspense>
                }
            />
            {/* ) : (
        <Route path="*" element={<Navigate to="/qr/:restaurantId/:branchId" replace={true} />} />) */}
            {/* temporal get otp route */}
            <Route
                path="/get-otp"
                element={
                    <div>
                        <Suspense fallback={<Loader />}>
                            <GetOTP />
                        </Suspense>
                    </div>
                }
            />
        </Routes>
    )
}


const mapStateToProps = (state) => {
    return {
        splashScreen: state.designs.splashScreen,
        token: state.auth.token,
        selectedRestaurant: state.appSource.appSource === "dine-in"
            ? state.restaurants.dineInSelectedRestaurant
            : state.appSource.appSource === "pay"
                ? state.restaurants.paySelectedRestaurant
                : state.appSource.appSource === "QR"
                    ? state.restaurants.QRSelectedRestaurant
                    : state.restaurants.selectedRestaurant,
        getRestaurantsLoading: state.restaurants.getRestaurantsLoading,
    };
};

export default connect(mapStateToProps, null)(RoutesContainer);
