import {
  GET_EVENTS_REQUEST,
  GET_EVENTS_SUCCESS,
  GET_EVENTS_ERROR,
  CHANGE_EVENTS_VIEW,
  RESET_EVENTS,
  CLEAR_ALL_EVENTS_DATA,
} from "../actions/EventsActions";

const initialState = {
  loading: false,
  events: [],
  view: "",
  //DINE IN
  dineInEvents: [],
  dineInView: "",
  //PAY
  payEvents: [],
  payView: "",
  //QR
  QREvents: [],
  QRView: "",
};

function events(state = initialState, action) {
  const appSource = action?.payload?.appSource;
  const eventsState =
    appSource === "dine-in"
      ? "dineInEvents"
      : appSource === "pay"
        ? "payEvents"
        : appSource === "QR"
          ? "QREvents"
          : "events";
  const viewState =
    appSource === "dine-in"
      ? "dineInView"
      : appSource === "pay"
        ? "payView"
        : appSource === "QR"
          ? "QRView"
          : "view";
  switch (action.type) {
    case GET_EVENTS_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_EVENTS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        [eventsState]: action.payload.events,
      });
    case GET_EVENTS_ERROR:
      return Object.assign({}, state, {
        loading: false,
      });
    case CHANGE_EVENTS_VIEW:
      return Object.assign({}, state, {
        [viewState]: action.payload?.eView,
      });
    case RESET_EVENTS: 
      return Object.assign({}, state, {
        loading: false,
        [eventsState]: []
      })
    case CLEAR_ALL_EVENTS_DATA:
      return initialState;
    // return Object.assign({}, state, { [eventsState]: [] });
    default:
      return state;
  }
}

export default events;
